import React from 'react'

import ContactHero from './hero'
import ContactForm from './form'
import ContactDetails from './details'
import { Divider } from '../../styles'

const Contact = () => {
  return (
    <>
      <ContactHero />
      <ContactForm />
      <Divider />
      <ContactDetails />
    </>
  )
}

export default Contact
