import React, { useState } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import {
  SubHeadingText,
  Button,
  FormLabel,
  FormInput,
  FormTextArea,
  FormError,
} from '../../styles'
import media from '../../styles/media'
import Option from 'muicss/lib/react/option'
import Select from 'muicss/lib/react/select'
import 'muicss/dist/css/mui.min.css'
import ReCAPTCHA from 'react-google-recaptcha'

const ContactFormCon = styled.div`
  overflow: hidden;
  margin: var(--gutter-s) var(--gutter-s) var(--gutter-m);
  .text {
    margin: var(--gutter-s) 0;
    display: block;
  }
  .inquirey {
    border: 1px solid #e5e5e5;
    width: 100%;
    margin-bottom: 20px;
    &.mui-select {
      padding: 5px 15px;
      width: 90%;
    }
    select {
      border-bottom: 0;
      color: rgb(118, 118, 118);
      font-size: 14px;
      line-height: 21px;
    }
    &.mui-select__menu {
      &.mui--is-selected {
        background-color: var(--blue);
        color: var(--white);
      }
    }
    .mui-select__menu
      > div:not(.mui-optgroup__label):not(.mui--is-disabled):hover {
      background-color: #e0e0e0;
      background-color: var(--blue);
      color: var(--white);
    }
  }
  ${media.mobileL`
  .inquirey {
    &.mui-select {
      width: 95%;
    }
  }
  `}
  ${media.laptop`
    margin: var(--gutter-s) var(--gutter-xl) var(--gutter-m);
    .text {
      margin: var(--gutter-m) 0;
      display: grid;
      grid-template-columns: 0.6fr 1fr;
    }
    .inquirey {
      border: 1px solid #e5e5e5;
      width: 600px;
    &.mui-select {
      padding: 10px 15px;
      width: 600px;
    }
    }
  `}
`

const ContactForm = () => {
  const [showFormSuccess, setFormSuccess] = useState(false)
  const [recaptchaChecked, setRecaptchaChecked] = useState(false)

  // const encode = data => {
  //   return Object.keys(data)
  //     .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
  //     .join('&')
  // }

  const recaptchaChange = () => {
    setRecaptchaChecked(true)
  }

  return (
    <ContactFormCon>
      <div className="text">
        <SubHeadingText>Your Information</SubHeadingText>
        <div className="form">
          <Formik
            initialValues={{
              enquiries: '',
              name: '',
              email: '',
              phone: '',
              company: '',
              inquirey: '',
              messsage: '',
            }}
            validate={values => {
              const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
              let errors = {}
              if (!values.name) {
                errors.name = 'Required '
              }
              if (!values.email || !emailRegex.test(values.email)) {
                errors.email = 'Required'
              }
              if (
                !values.inquirey ||
                values.inquirey === 'Placeholder' ||
                values.inquirey === ''
              ) {
                errors.inquirey = 'Please select an option'
              }
              return errors
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              fetch(
                'https://xpmoporgyd.execute-api.us-east-1.amazonaws.com/beta',
                {
                  method: 'POST',
                  mode: 'cors',
                  cache: 'no-cache',
                  body: JSON.stringify(values),
                  headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                  },
                }
              )
                .then(() => {
                  setFormSuccess(true)
                  resetForm({})
                })
                .catch(() => {
                  alert('Error')
                })
                .finally(() => setSubmitting(false))
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form method="POST">
                <div className="formField">
                  <FormLabel htmlFor="text">Name *</FormLabel> <br />
                  <FormInput
                    type="text"
                    name="name"
                    id="name"
                    placeholder="* Full Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  {errors.name && (
                    <FormError className="error">
                      <br />
                      {errors.name && touched.name && errors.name}
                      <br />
                      <br />
                    </FormError>
                  )}
                </div>
                <div className="formField">
                  <FormLabel htmlFor="email">Email Address *</FormLabel> <br />
                  <FormInput
                    type="email"
                    name="email"
                    id="email"
                    placeholder="* Email Address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {errors.email && (
                    <FormError className="error">
                      <br />
                      {errors.email && touched.email && errors.email}
                      <br />
                      <br />
                    </FormError>
                  )}
                </div>
                <div className="formField">
                  <FormLabel htmlFor="phone">Phone Number</FormLabel> <br />
                  <FormInput
                    className="phone"
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="Phone Number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                  />
                </div>
                <div className="formField">
                  <FormLabel htmlFor="company">Company Name</FormLabel> <br />
                  <FormInput
                    className="company"
                    type="text"
                    name="company"
                    id="company"
                    placeholder="Company Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.company}
                  />
                </div>
                <div className="formField">
                  <FormLabel htmlFor="inquirey">I need help with</FormLabel>{' '}
                  <br />
                  <Select
                    name="inquirey"
                    id="inquirey"
                    className="inquirey"
                    defaultValue="Placholder"
                  >
                    <Option
                      value="Placeholder"
                      label="Select the type of inquiry"
                    />
                    <Option value="Account Support" label="Account Support" />
                    <Option value="Order Support" label="Order Support" />
                    <Option
                      value="Technical Support"
                      label="Technical Support"
                    />
                  </Select>
                  {errors.inquirey && (
                    <FormError className="error select">
                      <br />
                      {errors.inquirey && touched.inquirey && errors.inquirey}
                      <br />
                      <br />
                    </FormError>
                  )}
                </div>
                <div className="formField">
                  <FormLabel htmlFor="messsage">Write a message</FormLabel>{' '}
                  <br />
                  <FormTextArea
                    className="messsage"
                    type="text"
                    name="messsage"
                    id="messsage"
                    placeholder="Write a messsage"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.messsage}
                  />
                </div>
                <br />
                <ReCAPTCHA
                  sitekey="6LcDL9cZAAAAAOLTHHcBpNBRKMrsg0kYrYuIU5Lq"
                  onChange={recaptchaChange}
                />
                <br />
                <Button
                  type="submit"
                  disabled={isSubmitting || !recaptchaChecked}
                  className="form-button"
                >
                  <span className="link-primary">Submit</span>
                </Button>
                {showFormSuccess && (
                  <div className="success">
                    <h4>Thank you for subscribing! </h4>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ContactFormCon>
  )
}

export default ContactForm
