import React from 'react'
import styled from 'styled-components'
import { HeadingText, BodyText } from '../../styles'
import media from '../../styles/media'
import Navigation from '../Navigation'

const ContactHeroCon = styled.div`
  height: 22vh;
  overflow: hidden;
  margin: var(--gutter-s) var(--gutter-s);
  .text {
  }
  ${media.mobileL`
    height: 35vh;
  `}
  ${media.laptop`
    height: 20vh;
    margin: var(--gutter-m) var(--gutter-xl) var(--gutter-s);
  `}
`

const ContactHero = () => {
  return (
    <>
      <Navigation />
      <ContactHeroCon>
        <div className="text">
          <HeadingText className="smallWidth black smaller">
            Contact
          </HeadingText>
          <BodyText className="black contactLength">
            Need help? One of our dedicated sales or support team will be able
            to help you. Enter your details below and we’ll get back to you.
          </BodyText>
        </div>
      </ContactHeroCon>
    </>
  )
}

export default ContactHero
