import React from 'react'

import SEO from '~/components/seo'
import Contact from '../components/Contact'
import Footer from '../components/Footer'

const ContactPage = () => (
  <>
    <SEO title="Contact" keywords={[`gatsby`, `application`, `react`]} />
    <Contact />
    <Footer />
  </>
)

export default ContactPage
