import React from 'react'
import styled from 'styled-components'
import {
  SubHeadingText,
  DetailsText,
  DetailsLink,
  HeadingText,
} from '../../styles'
import media from '../../styles/media'
import { isMobile } from 'react-device-detect'

const ContactDetailsCon = styled.div`
  height: auto;
  overflow: hidden;
  margin: var(--gutter-s);
  .text {
    margin: var(--gutter-m) 0;
    display: block;
    .contact-details {
      display: block;
    }
  }
  ${media.laptop`
    margin: var(--gutter-m) var(--gutter-xl) var(--gutter-s);
    .text {
      margin: var(--gutter-m) 0;
      display: grid;
      grid-template-columns: 0.6fr 1fr;
      .contact-details {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }
  `}
`

const ContactDetails = () => {
  return (
    <ContactDetailsCon>
      <div className="text">
        {isMobile && (
          <HeadingText className="black">Contact Details</HeadingText>
        )}
        {!isMobile && <SubHeadingText>Contact Details</SubHeadingText>}
        <div className="contact-details">
          <div>
            <DetailsText className="heading">Pretoria:</DetailsText>
            <DetailsText>Tel: 012 653 5319</DetailsText>
            <DetailsText>Fax: 012 6530398</DetailsText>
            <br />

            <DetailsText className="heading">Admin:</DetailsText>
            <DetailsText>
              Ashley Randall:{' '}
              <DetailsLink
                href="mailto: admin@quza.co.za"
                target="_blank"
                rel="noopener noreferrer"
                className="blue"
              >
                admin@quza.co.za
              </DetailsLink>
            </DetailsText>
            <br />

            <DetailsText className="heading">Sales Team:</DetailsText>
            <DetailsText>
              Yolandi Swanepoel:{' '}
              <DetailsLink
                href="mailto: yolandi@quza.co.za"
                target="_blank"
                rel="noopener noreferrer"
                className="blue"
              >
                yolandi@quza.co.za
              </DetailsLink>
            </DetailsText>
            <DetailsText>
              Stuart Warden:{' '}
              <DetailsLink
                href="mailto: stuart@quza.co.za"
                target="_blank"
                rel="noopener noreferrer"
                className="blue"
              >
                stuart@quza.co.za
              </DetailsLink>
            </DetailsText>
            <DetailsText>
              Partunia Sibanyoni:{' '}
              <DetailsLink
                href="mailto: sales@quza.co.za"
                target="_blank"
                rel="noopener noreferrer"
                className="blue"
              >
                sales@quza.co.za
              </DetailsLink>
            </DetailsText>
            <br />

            <DetailsText className="heading">Eastern Cape Branch:</DetailsText>
            <DetailsText className="heading">Sales Team:</DetailsText>
            <DetailsText>Andrew Coetzee</DetailsText>
            <DetailsText>Tel: 068 513 4721</DetailsText>
            <DetailsText>
              Email:{' '}
              <DetailsLink
                href="mailto: andrew@quza.co.za"
                target="_blank"
                rel="noopener noreferrer"
                className="blue"
              >
                andrew@quza.co.za
              </DetailsLink>
            </DetailsText>
            <br />
          </div>
          <div>
            <DetailsText className="heading">General Manager:</DetailsText>
            <DetailsText>
              Carllo Swanepoel:{' '}
              <DetailsLink
                href="mailto: Carllo@quza.co.za"
                target="_blank"
                rel="noopener noreferrer"
                className="blue"
              >
                Carllo@quza.co.za
              </DetailsLink>
            </DetailsText>
            <br />

            <DetailsText className="heading">
              Advertising &amp; Marketing:
            </DetailsText>
            <DetailsText>
              Mareli Stoop:{' '}
              <DetailsLink
                href="mailto: mareli@quza.co.za"
                target="_blank"
                rel="noopener noreferrer"
                className="blue"
              >
                mareli@quza.co.za
              </DetailsLink>
            </DetailsText>
            <DetailsText>Jaques Fourie</DetailsText>
            <br />

            <DetailsText className="heading">
              Programming &amp; Software Development:
            </DetailsText>
            <DetailsText>
              Vaughan Bentley:{' '}
              <DetailsLink
                href="mailto: Vaughan@quza.co.za"
                target="_blank"
                rel="noopener noreferrer"
                className="blue"
              >
                Vaughan@quza.co.za
              </DetailsLink>
            </DetailsText>
            <br />

            <DetailsText className="heading">Owner:</DetailsText>
            <DetailsText>Vaughan Bentley</DetailsText>
            <DetailsText>Tel: 083 302 5230</DetailsText>
            <DetailsText>
              Email:{' '}
              <DetailsLink
                href="mailto: Vaughan@quza.co.za"
                target="_blank"
                rel="noopener noreferrer"
                className="blue"
              >
                Vaughan@quza.co.za
              </DetailsLink>
            </DetailsText>
          </div>
        </div>
      </div>
    </ContactDetailsCon>
  )
}

export default ContactDetails
